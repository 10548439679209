import {noop, isFunction} from 'lodash'

const INIT_TIMEOUT = 200
const NUMBER_OF_INIT_RETRIES = 10

class PlayerApi {

  isInitialized = false
  playerReadyCallback = undefined
  powtoonDuration = undefined
  iframe = undefined
  _isPowtoonEnd = false

  constructor(iframe, onPlayerReady = noop) {
    const element = typeof(iframe) === 'string' ?
      document.getElementById(iframe) :
      iframe

    this.iframe = element.contentWindow

    this.playerReadyCallback = onPlayerReady
    window.onmessage = this._handleMessageFromIframe

    window.addEventListener('message', this._handleResponseFromIframe)

    retryUntil(
      () => this._postMessage('INIT'),
      () => this.isInitialized,
      INIT_TIMEOUT,
      NUMBER_OF_INIT_RETRIES
    )
  }

  _handleResponseFromIframe = e => {
    const {data: {type, response}} = e

    const resolve = this.responsePromises[type]

    resolve && resolve(response)
  }

  _handleMessageFromIframe = (event) => {
    switch (event.data.type) {
      case 'INIT_SUCCESS':
        this.isInitialized = true
        break
      case 'PLAYER_READY':
        this.powtoonDuration = event.data.duration
        this.playerReadyCallback()
        break
      case 'REPORT_IS_POWTOON_END':
        this._isPowtoonEnd = event.data.isPowtoonEnd
        break
      default:
        break
    }
  }

  responsePromises = {}

  _postMessage = (type, extraData) => {
    this.iframe.postMessage({type, ...extraData}, '*')

    const responsePromise = new Promise((resolve) => {
      this.responsePromises[type] = resolve
    })

    return responsePromise
  }

  play = (callback) => this._postMessage('PLAY').then(callback)
  pause = (callback) => this._postMessage('PAUSE').then(callback)
  jumpToStart = (callback) => this._postMessage('JUMP_TO_START').then(callback)
  jumpToNextHold = (callback) => this._postMessage('JUMP_TO_NEXT_HOLD').then(callback)
  jumpToPreviousHold = (callback) => this._postMessage('JUMP_TO_PREVIOUS_HOLD').then(callback)
  jumpToPreviousSlide = (callback) => this._postMessage('JUMP_TO_PREVIOUS_SLIDE').then(callback)
  jumpToNextSlide = (callback) => this._postMessage('JUMP_TO_NEXT_SLIDE').then(callback)
  setMovieMode = (callback) => this._postMessage('SET_MOVIE_MODE').then(callback)
  setSlideshowMode = (callback) => this._postMessage('SET_SLIDESHOW_MODE').then(callback)
  hideControls = (callback) => this._postMessage('HIDE_CONTROLS').then(callback)
  showControls = (callback) => this._postMessage('SHOW_CONTROLS').then(callback)
  loadPowtoon = (powtoonId, onPlayerReady) => {
    if (isFunction(onPlayerReady)) {
      this.playerReadyCallback = onPlayerReady
    }
    this._postMessage('LOAD_POWTOON', {powtoonId})
  }
  getDuration = () => this.powtoonDuration
  isPowtoonEnd = () => this._isPowtoonEnd
}

function retryUntil(func, testFunc, timeout, numberOfRetries) {
  func()
  let retriesLeft = numberOfRetries-1
  const intervalId = setInterval(() => {
    if (testFunc()) {
      clearInterval(intervalId)
    } else {
      func()
      retriesLeft--
      if (numberOfRetries && retriesLeft === 0) {
        clearInterval(intervalId)
      }
    }
  }, timeout)
}

export default PlayerApi
